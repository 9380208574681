import { Role } from './Role';

export enum AdminStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled'
}

export interface Admin {
  id: number
  username: string
  name: string
  phone: string
  status: AdminStatus
  createdAt: Date
  roles: Role[]
}

export type PartialAdmin = Partial<Admin>;
