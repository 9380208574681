import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(timezone);

export enum TimeZoneType {
  TAIPEI_TIME = 'Asia/Taipei',
  LONDON_TIME = 'Europe/London'
}

export const getUserTimeZone = () => {
  return dayjs.tz.guess();
};

export const formatLocalTime = (time: string | number | dayjs.Dayjs | Date, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!time) return;
  const customTimeZone = getUserTimeZone();
  return dayjs(time).tz(customTimeZone).format(format);
};

export const formatISOString = (dateTime: string | number | dayjs.Dayjs | Date, type?: 'end' | 'start') => {
  if (type === 'start') {
    return dateTime && dayjs(dateTime).startOf('day').toISOString();
  }
  if (type === 'end') {
    return dateTime && dayjs(dateTime).endOf('day').toISOString();
  }
  return dateTime && dayjs(dateTime).toISOString();
};

export const isTimeBefore = (time: string) => {
  return dayjs().isBefore(dayjs(time));
};
