
import {
  defineComponent,
  ref,
  unref,
  toRefs,
  watch,
  onMounted
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';
import { Rule, RuleItem } from 'async-validator';
import { useAdmin, useCreateAdmin, useUpdateAdmin } from '@/composables/api';
import useFormErrors, { isValidPassword } from '@/utils/validate';
import { CreateAdminData, CREATED_SUCCESSFULLY_TEXT, getRoles, MAX_PER_PAGE, ResponseError, UPDATED_SUCCESSFULLY_TEXT } from '@/services/api';
import { AdminStatus } from '@/interfaces/Admin';
import { normalizeRequestData } from '@/utils/normalization';
import { Role } from '@/interfaces';

const DEFAULT_FORM_VALUES: CreateAdminData = {
  username: '',
  password: '',
  name: '',
  phone: '',
  roleIds: [],
  status: AdminStatus.ENABLED
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const adminId = useRoute().params.id as string;
    const router = useRouter();
    const roles = ref<Role[]>([]);

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { data, dataUpdatedAt } = useAdmin({ adminId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });

    watch(dataUpdatedAt, () => {
      const admin = data.value.data;
      formValues.value = {
        ...admin,
        username: admin.username,
        password: null,
        name: admin.name,
        phone: admin.phone,
        roleIds: admin.roles.map(role => {
          return role.id;
        }),
        status: admin.status
      };
    });

    const { isLoading: isCreating, mutate: create } = useCreateAdmin();
    const { isLoading: isUpdating, mutate: update } = useUpdateAdmin();
    const { formErrors, bindFormItemError } = useFormErrors();

    const REQUIRED_RULE: Rule = {
      required: true,
      message: '此為必填欄位'
    };

    const rules = {
      username: [REQUIRED_RULE],
      password: [
        {
          required: !isEdit.value,
          trigger: ['blur', 'change'],
          validator: (rule: RuleItem, password: string) => {
            if (!rule.required && !password) {
              return true;
            }

            return isValidPassword(password);
          }
        }
      ],
      name: [REQUIRED_RULE],
      roleIds: [REQUIRED_RULE],
      status: [
        {
          required: true,
          trigger: 'change',
          message: '此為必填欄位'
        }
      ]
    };

    const fetchRoles = async() => {
      roles.value = (await getRoles({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (valid) {
          if (isEdit.value) {
            update({ adminId, data: normalizeRequestData(formValues.value) }, {
              onSuccess() {
                ElMessage.success(UPDATED_SUCCESSFULLY_TEXT);

                router.go(-1);
              },

              onError(error: ResponseError) {
                ElMessage.error(error.response?.data.message);
                formErrors.value = error.response?.data.message;
              }
            });

            return;
          }

          create({ data: normalizeRequestData(formValues.value) }, {
            onSuccess() {
              ElMessage.success(CREATED_SUCCESSFULLY_TEXT);

              formValues.value = DEFAULT_FORM_VALUES;
              router.go(-1);
            },
            onError(error: ResponseError) {
              ElMessage.error(error.response?.data.message);
              formErrors.value = error.response?.data.message;
            }
          });
        }
      });
    };

    onMounted(() => {
      fetchRoles();
    });

    return {
      formValues,
      formRef,
      rules,
      roles,
      AdminStatus,
      isCreating,
      isUpdating,
      formErrors,
      submitForm,
      bindFormItemError
    };
  }
});
